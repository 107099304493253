<template>
  <div class="has-tab-bar">
    <app-bar title="Betaal je boeking" :show-back-button="false" />
    <spinner-loader v-if="loading" />
    <div v-if="booking" class="container">
      <h1 v-if="!paymentStatus">
        Betaal je boeking bij {{ booking.commercial_location.name }}
      </h1>
      <template
        v-else-if="paymentStatus === 'paid' || paymentStatus === 'completed'"
      >
        <h1>Je boeking is betaald</h1>
        <p class="text-sm">
          Je hebt van ons een bevestiging per e-mail ontvangen van je betaling.
        </p>
      </template>

      <template v-else-if="paymentStatus === 'pending'">
        <h1>Je betaling wordt verwerkt</h1>
        <p class="text-sm">
          Op het moment dat je betaling bij ons binnen is ontvang je van ons een
          bevestiging per e-mail dat je betaald hebt.
        </p>
      </template>

      <template v-else>
        <h1>Je betaling is mislukt</h1>
        <p class="text-sm">
          Probeer het nog eens of neem contact met ons op via
          contact@inviplay.nl, dan proberen we het zo snel mogelijk voor je op
          te lossen.
        </p>
      </template>

      <div class="my-8 p-4 border border-gray-300 bg-gray-50">
        <p class="font-bold text-sm pb-2">Locatie</p>
        <p class="text-sm">{{ booking.commercial_location.name }}</p>
        <p class="text-sm">
          {{ booking.commercial_location.address.street }}
          {{ booking.commercial_location.address.houseNumber }}
        </p>
        <p class="text-sm">
          {{ booking.commercial_location.address.postalCode }}
          {{ booking.commercial_location.address.city }}
        </p>
        <p class="font-bold text-sm pb-2 pt-4">Datum en tijdstip</p>
        <p class="text-sm">{{ startAndEndDateTime }}</p>
        <p class="font-bold text-sm pb-2 pt-4">Prijs</p>
        <p class="text-sm">
          {{
            new Intl.NumberFormat('nl', {
              style: 'currency',
              currency: 'EUR'
            }).format(parseInt(booking.cost))
          }}
        </p>
        <template v-if="discount">
          <p class="font-bold text-sm pb-2 pt-4">Korting</p>
          <p class="text-sm">
            {{
              new Intl.NumberFormat('nl', {
                style: 'currency',
                currency: 'EUR'
              }).format(discount / 100)
            }}
          </p>
          <p class="font-bold text-sm pb-2 pt-4">Te betalen</p>
          <p class="text-sm">
            {{
              new Intl.NumberFormat('nl', {
                style: 'currency',
                currency: 'EUR'
              }).format(parseInt(booking.cost - discount / 100))
            }}
          </p>
        </template>
      </div>
      <button
        v-if="
          !paymentStatus ||
          (paymentStatus !== 'paid' &&
            paymentStatus !== 'completed' &&
            paymentStatus !== 'pending')
        "
        class="bg-green-600 disabled:bg-gray-400 text-white font-semibold py-2 px-12 rounded-lg h-12 text-xl"
        :disabled="isPaying"
        @click="startPayment"
      >
        {{ isPaying ? 'Je wordt doorgestuurd...' : 'Betaal je boeking' }}
      </button>
    </div>
    <error-modal :show="showErrorModal" @close="showErrorModal = false" />
    <tab-bar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapState } from 'vuex'
import api from '@/utils/api'
import { DateTime } from 'luxon'
import ErrorModal from '@/components/ErrorModal'

export default {
  name: 'BookingDetails',
  components: { AppBar, SpinnerLoader, TabBar, ErrorModal },
  data() {
    return {
      loading: false,
      bookingId: parseInt(this.$route.params.bookingId),
      hash: this.$route.params.hash,
      booking: null,
      isPaying: false,
      showErrorModal: false
    }
  },
  computed: {
    ...mapState({}),
    start() {
      return DateTime.fromISO(this.booking.startDateTime).toFormat(
        "cccc d LLLL yyyy 'van' T"
      )
    },
    end() {
      return DateTime.fromISO(this.booking.endDateTime).toFormat('T')
    },
    startAndEndDateTime() {
      return `${this.start} tot ${this.end}`
    },
    paymentStatus() {
      return this.booking?.payment_data?.payment_status
    },
    discount() {
      return this.booking?.discount
        ? this.booking.discount?.type === 'percentage'
          ? this.booking.cost * (this.booking.discount.value / 100) * 100
          : this.booking.discount.value
        : 0
    }
  },

  watch: {},
  mounted: async function () {
    this.loading = true
    try {
      const resp = await api.get(`booking/${this.bookingId}?hash=${this.hash}`)
      if (resp.status === 200) {
        this.booking = resp.data
      } else {
        this.$router.push({ name: 'NotFound' })
      }
    } catch (error) {
      this.$router.push({ name: 'NotFound' })
    }
    this.loading = false
  },
  methods: {
    async startPayment() {
      this.isPaying = true
      try {
        const response = await api.post(`booking/${this.bookingId}/pay`, {
          hash: this.hash
        })
        if (response.status === 200) {
          window.location.href = response.data.redirect_url
        } else {
          this.showErrorModal = true
        }
      } catch (error) {
        this.showErrorModal = true
        this.isPaying = false
      }
      this.isPaying = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>

<template>
  <div
    class="flex items-center border p-2 my-2 hover:bg-gray-100 hover:cursor-pointer text-sm"
  >
    <icon-base
      v-if="settlement.status === 'paid' || settlement.status === 'transfer'"
      class="text-green-600 w-8 mr-2"
      size="20"
    >
      <icon-check :stroke-width="3" />
    </icon-base>
    <icon-base
      v-else-if="settlement.status === 'current'"
      size="20"
      class="w-8 mr-2"
    >
      <icon-clock />
    </icon-base>

    <div class="flex-1">{{ settlementDate }}</div>
    <div class="w-16 font-semibold">
      {{ $n(settlementAmount / 100, 'currency') }}
    </div>
    <div class="w-4">
      <icon-base size="20">
        <icon-chevron-right />
      </icon-base>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import IconBase from '@/components/IconBase'
import IconCheck from '@/components/icons/IconCheck'
import IconClock from '@/components/icons/IconClock'
import IconChevronRight from '@/components/icons/IconChevronRight'

export default {
  name: 'Settlement',
  components: { IconBase, IconCheck, IconClock, IconChevronRight },
  props: {
    settlement: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    settlementDate() {
      return this.settlement?.status === 'current'
        ? DateTime.fromMillis(this.settlement.period_end * 1000)
            .plus({ days: 1 })
            .toFormat('ccc d LLL yyyy')
        : this.settlement?.status === 'paid' ||
          this.settlement?.status === 'transfer'
        ? DateTime.fromMillis(this.settlement.paid * 1000).toFormat(
            'ccc d LLLL yyyy'
          )
        : ''
    },
    settlementAmount() {
      return this.settlement?.status !== 'current'
        ? this.settlement.total_amount
        : this.settlement.amount_payable
    }
  },
  created: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>

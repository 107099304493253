<template>
  <div class="has-tab-bar">
    <app-bar title="ID check" :show-back-button="!merchantIsPending" />
    <spinner-loader v-if="loading" />
    <div class="container">
      <template v-if="merchantIsLiveAndVerified">
        <h1>Verificatie succesvol</h1>
        <p>Je hebt je bankrekeningnummer al geverifieerd.</p>
        <div class="mt-4">
          Ga terug naar de
          <a class="link" href="/">homepage</a>
        </div>
      </template>
      <template v-else-if="merchantIsPending">
        <h1>Even geduld...</h1>
        <p>
          Je bankrekening nummer wordt op dit moment geverifieerd, je ontvangt
          van ons een mail zodra dit is gebeurd. Doorgaans duurt dit 24 uur,
          mocht het langer duren stuur ons dan even een berichtje op
          contact@inviplay.nl. Je kunt je activiteit al wel aanmaken en
          deelnemers kunnen al wel betalen voor jouw activiteit of
          strippenkaart. Zodra jouw ID is geverifieerd worden deze betalingen
          uiteraard gewoon aan je uitgekeerd.
        </p>
        <div
          v-if="statusLink"
          class="mt-4 p-4 border rounded-lg border-green-600"
        >
          <a class="link" :href="statusLink" target="_blank">Klik hier</a>
          om de status van de verificatie bij Online Payment Platform te
          bekijken en om eventuele gegevens nog aan te leveren.
        </div>
        <div class="mt-4">
          Ga terug naar de
          <a class="link" href="/">homepage</a>
        </div>
      </template>
      <template v-else>
        <h1>Controleer je gegevens</h1>
        <p>
          Onze betaalprovider Online Payment Platform handelt alle uitbetalingen
          af voor Inviplay. Om dat te mogen doen moeten zij (net als elke bank)
          eerst verifiëren aan wie zij uitbetalen. Vandaar dat ze een ID check
          doen via een transactie van 10 cent òf door het uploaden van een ID.
          <br />
          <a href="#" class="link" @click.prevent="showInfoModal = true">
            Lees hier meer over de ID check
          </a>
        </p>
        <p class="mt-4">
          Controleer eerst of onderstaande gegevens kloppen (en vul zo nodig
          aan):
        </p>
        <div class="mt-8">
          <input-field
            v-model="firstName"
            label="Voornaam"
            :disabled="loading"
          />
          <input-field
            v-model="lastName"
            class="mt-4"
            label="Achternaam"
            :disabled="loading"
          />
          <div class="relative mb-8 mt-4">
            <input-field
              id="phoneNumber"
              v-model="phoneNumber"
              label="Telefoonnummer"
              class="mb-2"
              default-value="Telefoon nummer"
              @input="storePhoneNumber"
            />
            <i
              v-if="phoneNumber && !phoneNumberError"
              class="absolute top-12 right-4 text-green-600 fal fa-check"
            />
            <div v-if="phoneNumberError" class="text-red-800 mt-3 text-sm">
              Dit geen correct telefoonnummer
            </div>
          </div>
          <div class="mb-8">
            <radio-button
              :selected="merchantType"
              name="merchantType"
              label="Ik wil op een privé bankrekening uitbetaald worden"
              value="consumer"
              @change="(value) => (merchantType = value)"
            />
            <radio-button
              :selected="merchantType"
              name="merchantType"
              label="Ik wil op een zakelijke bankrekening uitbetaald worden"
              value="business"
              @change="(value) => (merchantType = value)"
            />
            <p
              v-if="merchantType === 'business'"
              class="text-xs border rounded-lg p-4 border-yellow-600 my-4"
            >
              Om een zakelijke bankrekening te kunnen verifiëren, heeft Online
              Payment Platform naast een bankrekeningnummer verificatie nog
              aanvullende gegevens nodig. Na het starten van de verificatie
              ontvang je van ons een mail met verdere instructies. Let op: het
              verifiëren van een zakelijke bankrekening kan enkele dagen duren.
            </p>
            <input-field
              v-if="merchantType === 'business'"
              id="cocNumber"
              v-model="cocNumber"
              label="KVK nummer"
              class="mb-2"
              default-value="12345678"
            />
            <i
              v-if="cocNumber && cocNumberIsValid"
              class="absolute top-12 right-4 text-green-600 fal fa-check"
            />
            <div
              v-if="cocNumber && !cocNumberIsValid"
              class="text-red-800 mt-3 text-sm"
            >
              Dit geen correct KVK nummer
            </div>
            <form-checkbox
              v-model="termsChecked"
              label=""
              checkbox-position="left"
              checkbox-size="small"
              class="-ml-1 mt-6"
            >
              <span class="text-small">
                Ik ga akkoord met de
                <a
                  class="link"
                  href="https://onlinepaymentplatform.com/media/cnt/opp-gebruiksvoorwaarden-versie-04112022.pdf"
                  target="_blank"
                >
                  Gebruiksvoorwaarden
                </a>
                van Online Payment Platform.
              </span>
            </form-checkbox>
          </div>
          <button
            class="button button-primary w-full"
            :disabled="!buttonEnabled"
            @click="createMerchant"
          >
            Start verificatie
          </button>
        </div>
      </template>
      <modal :show="showErrorModal" @close="showErrorModal = false">
        <template v-slot:header>Er gaat iets fout...</template>
        <div>
          <p>
            Er is iets fout gegaan bij het verifiëren van je bankrekeningnummer.
            Probeer het later nog eens of neem contact met ons op via
            contact@inviplay.nl
          </p>
        </div>
      </modal>
      <modal :show="showRedirectModal" @close="closeModalAndRedirect">
        <template v-slot:header>Je wordt doorgestuurd</template>
        <div>
          <p>
            Je wordt zo doorgestuurd naar onze betaal provider Online Payment
            Platform.
          </p>
          <p v-if="merchantType === 'consumer'" class="mt-4">
            Je kunt daar verificatie afronden door een bedrag van 10 cent over
            te maken naar Online Payment Platform.
          </p>
          <p v-else-if="merchantType === 'business'" class="mt-4">
            Voor het verifiëren van een zakelijke bankrekening heeft Online
            Payment Platform aanvullende gegevens nodig. Je gaat nu naar het
            overzichtsscherm van Online Payment Platform. Hier kun je je
            bankrekeningnummer verifiëren en de aanvullende zaken aanleveren.
          </p>
        </div>
        <template v-slot:footer>
          <button
            class="button button-primary w-full"
            @click="closeModalAndRedirect"
          >
            Verder naar Online Payment Platform
          </button>
        </template>
      </modal>
      <id-check-info-modal
        :show="showInfoModal"
        @close="showInfoModal = false"
      />
    </div>
    <tab-bar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapState } from 'vuex'
import InputField from '../components/InputField.vue'
import {
  PARTICIPANT_UPDATE_PHONENUMBER,
  PARTICIPANT_OWN
} from '../store/actions/participant'
import api from '@/utils/api'
import Modal from '@/components/Modal'
import RadioButton from '@/components/RadioButton'
import FormCheckbox from '@/components/FormCheckbox'
import IdCheckInfoModal from '@/components/IdCheckInfoModal'

export default {
  name: 'IDCheck',
  components: {
    AppBar,
    SpinnerLoader,
    TabBar,
    InputField,
    Modal,
    RadioButton,
    FormCheckbox,
    IdCheckInfoModal
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      phoneNumberError: false,
      loading: false,
      showErrorModal: false,
      errorCode: undefined,
      merchantType: 'consumer',
      cocNumber: undefined,
      showRedirectModal: false,
      termsChecked: false,
      showInfoModal: false
    }
  },
  computed: {
    ...mapState({
      profile: (state) => state.participant.ownProfile
    }),
    merchantIsLiveAndVerified() {
      return (
        this.profile.merchant?.compliance?.status === 'verified' &&
        this.profile.merchant?.status === 'live'
      )
    },
    merchantIsPending() {
      return (
        this.profile.merchant?.status === 'new' ||
        this.profile.merchant?.status === 'pending' ||
        this.profile.merchant?.compliance?.status === 'pending' ||
        this.profile.merchant?.compliance?.status === 'unverified'
      )
    },
    buttonEnabled() {
      return (
        !!this.firstName &&
        !!this.lastName &&
        !!this.phoneNumber &&
        !this.phoneNumberError &&
        !this.loading &&
        this.termsChecked &&
        (this.merchantType === 'business' ? this.cocNumberIsValid : true)
      )
    },
    cocNumberIsValid() {
      return !!this.cocNumber && RegExp(/^[0-9]{8}$/).test(this.cocNumber)
    },
    statusLink() {
      return this.profile.merchant?.compliance?.overview_url || null
    }
  },
  watch: {},
  mounted: function () {
    this.firstName = this.profile.firstName
    this.lastName = this.profile.lastName
    this.phoneNumber = this.profile.phoneNumber
  },
  methods: {
    phoneNumberIsValid() {
      const regex = RegExp(
        /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/
      )
      return regex.test(this.phoneNumber)
    },
    storePhoneNumber() {
      setTimeout(() => {
        if (this.phoneNumberIsValid()) {
          this.phoneNumberError = false
          this.$store.dispatch(PARTICIPANT_UPDATE_PHONENUMBER, {
            phoneNumber: this.phoneNumber
          })
        } else {
          this.phoneNumberError = true
        }
      }, 300)
    },
    async createMerchant() {
      this.loading = true
      try {
        const response = await api.post(
          `merchant?return_url=${
            this.$route.query?.redirectTo || this.$route.path
          }?action=merchant_verification`,
          {
            type: this.merchantType === 'business' ? 'business' : 'consumer',
            ...(this.merchantType === 'business'
              ? { cocNumber: this.cocNumber }
              : {})
          }
        )
        this.loading = false
        if (
          this.merchantType === 'consumer' &&
          response?.data?.bank_accounts?.length
        ) {
          this.showRedirectModal = true
          this.redirectUrl = response.data.bank_accounts[0].verification_url
        } else if (this.merchantType === 'business') {
          this.showRedirectModal = true
          this.redirectUrl = response.data.compliance.overview_url
        } else {
          this.showErrorModal = true
        }
      } catch (error) {
        this.loading = false
        this.errorCode = error.response?.data?.error?.code
        this.showErrorModal = true
      }
    },
    closeModalAndRedirect() {
      this.showRedirectModal = false
      this.$store.dispatch(PARTICIPANT_OWN)
      if (this.merchantType === 'consumer') {
        window.location.href = this.redirectUrl
      } else {
        window.open(this.redirectUrl, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>

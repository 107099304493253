<template>
  <div>
    <app-bar
      title="Oeps"
      :show-back-button="false"
      :show-profile-button="false"
    />
    <spinner-loader v-if="loading" />
    <div class="container">
      <h1>Pagina niet gevonden</h1>
      <div>De pagina die je zocht is niet gevonden of bestaat niet meer</div>
      <div class="mt-4">
        Ga terug naar de
        <a class="link" href="/">homepage</a>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapState } from 'vuex'

export default {
  name: 'NotFound',
  components: { AppBar, SpinnerLoader },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({})
  },
  watch: {},
  created: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>

<template>
  <div class="has-tab-bar">
    <app-bar title="" :show-back-button="true" />
    <spinner-loader v-if="loading" />
    <div class="container">
      <h1>Uitbetalingen</h1>
      <template v-if="!merchantIsLiveAndVerified">
        <id-check-component
          v-if="errorCode === 'ERROR_NO_MERCHANT_FOUND'"
          redirect-to="/settlements"
        />
        <div
          v-else
          class="border rounded-lg border-yellow-600 p-4 mb-8 text-yellow-700 flex items-center"
        >
          <div class="mr-4 flex-initial">
            <icon-base size="32" class="bg-yellow-600 rounded-full p-1.5">
              <icon-clock :stroke-width="3" class="text-white" />
            </icon-base>
          </div>
          <p class="text-sm">
            Je bankrekening nummer wordt op dit moment geverifieerd, je ontvangt
            van ons een mail zodra dit is gebeurd. Doorgaans duurt dit 24 uur,
            mocht het langer duren stuur ons dan even een berichtje op
            contact@inviplay.nl. Je kunt je activiteit of strippenkaart al wel
            aanmaken en deelnemers kunnen al wel betalen voor jouw activiteit of
            strippenkaart. Zodra jouw ID is geverifieerd worden deze betalingen
            uiteraard gewoon aan je uitgekeerd.
          </p>
        </div>
        <div v-if="statusLink" class="mt-4">
          <a class="link" :href="statusLink" target="_blank">Klik hier</a>
          om de status van de verificatie bij Online Payment Platform te
          bekijken en om eventuele gegevens nog aan te leveren.
        </div>
      </template>
      <template v-else>
        <div
          class="border rounded-lg border-green-600 p-4 mb-8 text-green-700 flex items-center"
        >
          <div class="mr-4">
            <icon-base size="32" class="bg-green-600 rounded-full p-1.5">
              <icon-check :stroke-width="3" class="text-white" />
            </icon-base>
          </div>
          <p class="text-sm">
            Je bankrekening nummer is geverifieerd. Je wordt uitbetaald op
            <strong>{{ bankAccount.iban }}</strong>
            ten name van
            <strong>{{ bankAccount.name }}</strong>
          </p>
        </div>
        <h2>Geplande uitbetaling</h2>
        <div
          v-if="currentSettlement"
          @click="
            () =>
              $router.push(
                `/profile/settlements/${currentSettlement.uid}/${currentSettlement.specifications[0].uid}/`
              )
          "
        >
          <settlement :settlement="currentSettlement" />
        </div>
        <div v-else>
          <p class="mb-8 text-sm text-gray-600">
            Er is nog geen (nieuwe) uitbetaling gepland. Zodra deelnemers
            betalen voor jouw activiteit of strippenkaart wordt er een
            uitbetaling gepland.
          </p>
        </div>

        <h2>Uitbetaalde bedragen</h2>
        <div v-if="pastSettlements && pastSettlements.length">
          <div
            v-for="settlement in pastSettlements"
            :key="settlement.id"
            @click="
              () =>
                $router.push(
                  `/profile/settlements/${settlement.uid}/${settlement.specifications[0].uid}/`
                )
            "
          >
            <settlement :settlement="settlement" />
          </div>
        </div>
        <div v-else>
          <p class="mb-8 text-sm text-gray-600">
            Er zijn nog geen uitbetalingen gedaan.
          </p>
        </div>
      </template>
    </div>
    <tab-bar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapState } from 'vuex'
import api from '@/utils/api'
import Settlement from '@/components/Settlement'
import IconBase from '@/components/IconBase'
import IconCheck from '@/components/icons/IconCheck'
import IconClock from '@/components/icons/IconClock'
import IdCheckComponent from '@/components/IdCheckComponent'

export default {
  name: 'Settlements',
  components: {
    AppBar,
    SpinnerLoader,
    TabBar,
    Settlement,
    IconBase,
    IconCheck,
    IconClock,
    IdCheckComponent
  },
  data() {
    return {
      loading: false,
      settlements: [],
      errorCode: undefined
    }
  },
  computed: {
    ...mapState({
      profile: (state) => state.participant.ownProfile
    }),
    merchantIsLiveAndVerified() {
      return (
        this.profile.merchant?.compliance?.status === 'verified' &&
        this.profile.merchant?.status === 'live'
      )
    },
    merchantIsPending() {
      return (
        this.profile.merchant?.status === 'new' ||
        this.profile.merchant?.status === 'pending' ||
        this.profile.merchant?.compliance?.status === 'pending' ||
        this.profile.merchant?.compliance?.status === 'unverified'
      )
    },
    bankAccount() {
      return {
        iban: this.profile.merchant?.bank_accounts[0].account?.account_iban,
        name: this.profile.merchant?.bank_accounts[0].account?.account_name
      }
    },
    currentSettlement() {
      return this.settlements?.data?.find(
        (settlement) => settlement.status === 'current'
      )
    },
    pastSettlements() {
      return this.settlements?.data
        ?.filter((settlement) => settlement.status !== 'current')
        .sort((a, b) => b.period_end - a.period_end)
    },
    statusLink() {
      return this.profile.merchant?.compliance?.overview_url || null
    }
  },
  watch: {},
  mounted: async function () {
    this.loading = true
    try {
      const resp = await api.get('/payment/settlements')
      if (resp.status === 200 && resp.data) {
        this.settlements = resp.data
      }
    } catch (error) {
      this.errorCode = error.response?.data?.error?.code
    }
    this.loading = false
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{class:{
    'checkbox-right': _vm.checkboxPosition === 'right',
    'font-size-small': _vm.fontSize === 'small',
    'border border-green-800 rounded-lg bg-black bg-opacity-10 pl-8 pr-2 pt-1 pb-1 text-sm mr-3 mb-3 text-white text-opacity-70': _vm.labelBorder,
    'bg-white bg-opacity-100 text-green-600 border-green-600':
      _vm.value && _vm.labelBorder
  },style:({ color: _vm.labelColor })},[_vm._v(" "+_vm._s(_vm.label)+" "),_vm._t("default"),_c('input',{attrs:{"type":"checkbox","required":_vm.required,"disabled":_vm.disabled},domProps:{"checked":_vm.value},on:{"change":function($event){return _vm.$emit('input', $event.target.checked)}}}),_c('div',{class:{
      'top-1': _vm.labelBorder === true,
      'checkbox-size-small': _vm.checkboxSize === 'small',
      indicator: _vm.indicator,
      'date-list-checkbox': _vm.dateListCheckbox === true,
      'date-list-label': _vm.dateListLabel
    }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <label
    :style="{ color: labelColor }"
    :class="{
      'checkbox-right': checkboxPosition === 'right',
      'font-size-small': fontSize === 'small',
      'border border-green-800 rounded-lg bg-black bg-opacity-10 pl-8 pr-2 pt-1 pb-1 text-sm mr-3 mb-3 text-white text-opacity-70': labelBorder,
      'bg-white bg-opacity-100 text-green-600 border-green-600':
        value && labelBorder
    }"
  >
    {{ label }}
    <slot />
    <input
      type="checkbox"
      :checked="value"
      :required="required"
      :disabled="disabled"
      @change="$emit('input', $event.target.checked)"
    />
    <div
      :class="{
        'top-1': labelBorder === true,
        'checkbox-size-small': checkboxSize === 'small',
        indicator: indicator,
        'date-list-checkbox': dateListCheckbox === true,
        'date-list-label': dateListLabel
      }"
    />
  </label>
</template>

<script>
export default {
  name: 'FormCheckbox',
  props: {
    value: {
      type: Boolean,
      required: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    labelColor: {
      type: String,
      required: false,
      default: '#000'
    },
    checkboxPosition: {
      type: String,
      required: false,
      default: 'left'
    },
    fontSize: {
      type: String,
      required: false,
      default: ''
    },
    checkboxSize: {
      type: String,
      required: false,
      default: ''
    },
    dateListCheckbox: {
      type: Boolean,
      required: false
    },
    dateListLabel: {
      type: Boolean,
      required: false
    },
    labelBorder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      indicator: true
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';

label {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 1.125rem;
}

label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
label .indicator {
  position: absolute;
  top: 0px;
  left: 0;
  width: 30px;
  height: 30px;
  background: #fff;
  border: 1px solid $ipGreen;
  border-radius: 4px;
}
label input:checked ~ .indicator {
  background: $ipGreen;
}
label input:checked ~ label {
  background: white;
}
label input:disabled ~ .indicator {
  border-color: #aaa;
}
label .indicator.checkbox-size-small {
  top: 4px;
  left: 5px;
  width: 22px;
  height: 22px;
  border: 1px solid $ipGreen;
}

label .indicator:after {
  content: '';
  position: absolute;
  display: none;
  left: 8px;
  top: 1px;
  width: 11px;
  height: 21px;
  border: solid $ipWhite;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
}
label .checkbox-size-small.indicator:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 14px;
  border: solid $ipWhite;
  border-width: 0 3px 3px 0;
}

@media all and (max-width: 575px) {
  label .indicator.checkbox-size-small {
    top: 2px;
    left: 2px;
  }
}

label input:disabled ~ .indicator:after {
  border-color: #7b7b7b;
}
label input:checked ~ .indicator:after {
  display: block;
}
label.checkbox-right {
  padding-left: 0;
  padding-right: 40px;
  font-size: 1rem;
}
label.checkbox-right .indicator {
  left: auto;
  right: 0;
}
label.checkbox-right .date-list-checkbox {
  right: 18px;
}
label.checkbox-right .date-list-label {
  display: none;
}

@media all and (max-width: 575px) {
  label.font-size-small {
    vertical-align: center;
    font-size: 1rem;
  }

  label .indicator.checkbox-size-small {
    top: 3px;
    left: 5px;
    width: 20px;
    height: 20px;
    border: 1px solid $ipGreen;
  }

  label .checkbox-size-small.indicator:after {
    left: 6px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid $ipWhite;
    border-width: 0 2px 2px 0;
  }
}
</style>

<template>
  <div class="has-tab-bar">
    <app-bar title="" :show-back-button="true" />
    <spinner-loader v-if="loading" />
    <div class="container">
      <h1>Uitbetaling</h1>
      <template v-if="settlement.data && Object.keys(settlement.data).length">
        <div class="border round-lg p-4 text-sm">
          <div class="flex justify-between">
            <span class="text-gray-600">Uitbetaald op</span>
            <span class="text-gray-600">
              {{ payoutDate ? payoutDate : 'Nog niet uitbetaald' }}
            </span>
          </div>
          <div class="flex justify-between">
            <span class="text-gray-600">Bedrag uitbetaald</span>
            <span class="text-gray-600">
              {{ $n(amountPaid, 'currency') }}
            </span>
          </div>
          <div class="flex justify-between">
            <span class="text-gray-600">Periode start</span>
            <span class="text-gray-600">{{ periodStart }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-gray-600">Periode einde</span>
            <span class="text-gray-600">{{ periodEnd }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-gray-600">Aantal transacties</span>
            <span class="text-gray-600">
              {{ settlement.data.number_of_transactions }}
            </span>
          </div>
          <div class="flex justify-between">
            <span class="text-gray-600">Aantal terugbetalingen</span>
            <span class="text-gray-600">
              {{ settlement.data.number_of_refunds }}
            </span>
          </div>
        </div>
      </template>
      <template v-if="formattedSettlement">
        <h2>Transacties</h2>
        <div class="flex text-sm font-semibold py-1">
          <div class="w-24">Datum</div>
          <div class="flex-1">Beschrijving</div>
          <div class="w-14">Bedrag</div>
        </div>
        <div
          v-for="settlementData in formattedSettlement.data"
          :key="settlementData.reference"
          class="flex text-sm py-1"
        >
          <div class="w-24">{{ settlementData.formattedDate }}</div>
          <div class="flex-1">
            {{ settlementData.description }}
          </div>
          <div class="w-14">
            {{ $n(settlementData.amount_payable / 100, 'currency') }}
          </div>
        </div>
      </template>
    </div>
    <tab-bar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapState } from 'vuex'
import api from '@/utils/api'
import { DateTime } from 'luxon'

export default {
  name: 'SettlementDetails',
  components: { AppBar, SpinnerLoader, TabBar },
  data() {
    return {
      settlement: {},
      loading: false
    }
  },
  computed: {
    ...mapState({}),
    formattedSettlement() {
      return this.settlement.transactions?.length
        ? {
            ...this.settlement.transactions,
            data: this.settlement.transactions.map((row) => ({
              ...row,
              formattedDate: DateTime.fromMillis(row.date * 1000).toFormat(
                'dd-MM-yyyy'
              )
            }))
          }
        : null
    },
    payoutDate() {
      return this.settlement.data?.paid
        ? DateTime.fromMillis(this.settlement.data.paid * 1000).toFormat(
            'dd-MM-yyyy HH:mm'
          )
        : ''
    },
    amountPaid() {
      return this.settlement.data?.total_amount
        ? this.settlement.data.total_amount / 100
        : 0
    },
    periodStart() {
      return this.settlement.data?.period_start
        ? DateTime.fromMillis(
            this.settlement.data.period_start * 1000
          ).toFormat('dd-MM-yyyy HH:mm')
        : ''
    },
    periodEnd() {
      return this.settlement.data?.period_end
        ? DateTime.fromMillis(this.settlement.data.period_end * 1000).toFormat(
            'dd-MM-yyyy HH:mm'
          )
        : ''
    }
  },
  watch: {},
  mounted: async function () {
    this.loading = true
    const getSettlement = await api.get(
      `/payment/settlements/${this.$route.params.settlementUid}/${this.$route.params.specificationUid}`
    )
    if (getSettlement.status === 200) {
      this.settlement = getSettlement.data
    }
    this.loading = false
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>

<template>
  <div>
    <div
      class="flex flex-col flex-1 max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto"
    >
      <!-- Header -->
      <div
        class="flex flex-row justify-between gap-2 w-full h-12 sm:h-14 md:h-16 p-3"
      >
        <img class="max-h-full" src="@/assets/images/logo-new.png" />
        <span class="flex-1"></span>
        <a
          href="/register-preferences"
          class="text-white sm:font-medium text-xs sm:text-base bg-green-600 rounded-full flex items-center px-2 md:px-4 no-underline cursor-pointer"
        >
          AANMELDEN
        </a>
        <a
          href="login"
          class="text-green-600 sm:font-medium text-xs sm:text-base border border-green-600 rounded-full flex items-center px-2 md:px-4 no-underline cursor-pointer"
        >
          LOGIN
        </a>
      </div>

      <!-- First content block -->
      <div
        class="border mx-4 mt-8 rounded-lg bg-green-100 bg-opacity-30 border-green-400"
      >
        <h1
          class="text-4xl md:text-5xl mx-8 lg:w-8/12 text-green-900 font-bold"
        >
          {{ mainBlock.title }}
        </h1>
        <div class="flex flex-col sm:flex-row items-center sm:items-start">
          <div class="w-full sm:w-1/2 lg:w-2/3">
            <p
              class="mx-8 text-lg sm:text-xl md:text-2xl leading-6 md:leading-8 text-green-900 font-thin mb-8"
            >
              {{ mainBlock.content }}
            </p>
            <div
              class="flex flex-col items-center sm:items-start lg:justify-center lg:flex-row mb-8 mt-10 max-w-full lg:gap-4 ml-8"
            >
              <a
                href="https://apps.apple.com/nl/app/inviplay-invite-play/id1605262654"
                target="_blank"
              >
                <img
                  class="max-w-12"
                  src="@/assets/images/Appstore-Download.svg"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=nl.inviplay.app.prd"
                target="_blank"
              >
                <img
                  class="max-w-12 mt-4 lg:mt-0"
                  src="@/assets/images/Playstore-Download.svg"
                />
              </a>
            </div>
            <p
              v-if="showBookingLink"
              class="mx-8 text-lg sm:text-l md:text-xl leading-6 md:leading-8 text-green-900 font-thin mb-8 lg:justify-center max-w-full ml-8 items-center"
            >
              Of boek gelijk via {{ ' ' }}
              <a
                href="/locations"
                class="text-green-600 underline hover:text-green-500"
              >
                de website
              </a>
            </p>
          </div>
          <div class="relative w-full sm:w-1/2 lg:w-1/3 hidden sm:block">
            <img
              class="max-w-full mt-12 absolute right-8 -top-14 lg:-top-32 xl:-top-48"
              src="@/assets/images/landingpage-phone.png"
            />
          </div>
          <div class="w-3/4 sm:hidden mx-auto">
            <img
              class="max-w-full top-0 right-0"
              src="@/assets/images/landingpage-phone.png"
            />
          </div>
        </div>
      </div>

      <div class="mx-4">
        <h1
          class="font-bold text-green-600 mt-8 md:mt-12 lg:mt-24 mb-4 text-4xl text-center sm:text-5xl lg:text-6xl"
        >
          {{ subBlock.title }}
        </h1>
        <div
          class="text-green-900 text-center text-lg sm:w-2/3 xl:w-1/2 mx-auto sm:text-xl md:text-2xl font-normal sm:mt-6 md:mt-8 lg:mt-8"
        >
          {{ subBlock.content }}
        </div>
      </div>
    </div>
    <div class="w-full bg-green-600 mt-16 text-white">
      <div
        class="flex flex-col flex-1 max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto"
      >
        <div class="mx-4 mt-8 flex">
          <div class="w-1/2 lg:w-1/3 relative hidden md:block">
            <img
              class="max-w-full absolute px-8 top-4 lg:-top-16 xl:-top-32"
              src="@/assets/images/pick-activities.png"
            />
          </div>
          <div class="w-2/3 mx-8 pb-8">
            <h2 class="text-2xl">Aanmelden is gratis</h2>
            <div class="text-lg">
              Zo maken we sport toegankelijk voor iedereen!
            </div>
            <h2 class="text-2xl mt-8">Ontvang invites</h2>
            <div class="text-lg">
              Krijg uitnodigingen van jouw favoriete sportactiviteiten
            </div>
            <h2 class="text-2xl mt-8">Vind sporters</h2>
            <div class="text-lg">
              Organiseer een activiteit en vind extra spelers
            </div>
            <h2 class="text-2xl mt-8">Maak een groep</h2>
            <div class="text-lg">Maak een groep aan en nodig vrienden uit</div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full text-green-600">
      <div
        class="flex flex-col flex-1 max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto"
      >
        <div class="mx-4 mt-8 flex flex-col sm:flex-row">
          <div class="hidden xl:block xl:w-1/3"></div>
          <div class="w-full sm:w-1/2 xl:w-1/3 px-8">
            <h2 class="text-2xl mt-8">Ook voor verenigingen</h2>
            <div class="text-lg">
              Organiseer activiteiten voor leden & niet leden
            </div>
            <h2 class="text-2xl mt-8">Extra inkomsten</h2>
            <div class="text-lg">
              Betalende sporters die normaal niet op jullie club komen
            </div>
          </div>
          <div class="w-full sm:w-1/2 xl:w-1/3 px-8">
            <h2 class="text-2xl mt-8">Aangepast lidmaatschap</h2>
            <div class="text-lg">
              Deelnemers bekijken per keer of ze tijd hebben en betalen per keer
            </div>
            <h2 class="text-2xl mt-8">Geen gedoe met betalingen</h2>
            <div class="text-lg">
              Deelnemers aan jullie activiteiten rekenen via iDeal af
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full bg-green-600 mt-16 text-white">
      <div
        class="flex flex-col flex-1 max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto"
      >
        <div class="mx-4 mt-8 text-center">
          <div class="text-2xl mt-8">Ontdek de mogelijkheden van Inviplay</div>
          <div
            class="flex flex-col items-center sm:justify-center sm:flex-row mb-8 mt-10 max-w-full sm:gap-4"
          >
            <a
              href="https://apps.apple.com/nl/app/inviplay-invite-play/id1605262654"
              target="_blank"
            >
              <img
                class="max-w-12"
                src="@/assets/images/Appstore-Download.svg"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=nl.inviplay.app.prd"
              target="_blank"
            >
              <img
                class="max-w-12 mt-4 sm:mt-0"
                src="@/assets/images/Playstore-Download.svg"
              />
            </a>
          </div>
          <div class="text-lg mt-8">
            Met de app haal je het maximale uit Inviplay. Wil je liever de oude
            versie van Inviplay gebruiken? Klik dan
            <a
              class="no-underline font-bold hover:text-white text-white"
              href="/events"
            >
              hier
            </a>
          </div>
          <h2 class="text-4xl mt-8 mb-16">Invite & play!</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import landingPageContent from '@/assets/content/landingPageContent'

export default {
  name: 'LandingPage',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    showBookingLink: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    mainBlock() {
      return (
        landingPageContent[this.$route.name]?.main ||
        landingPageContent.default.main
      )
    },
    subBlock() {
      return (
        landingPageContent[this.$route.name]?.sub ||
        landingPageContent.default.sub
      )
    },
    pageTitle() {
      return this.title || 'Inviplay - Invite & Play'
    }
  },
  created: function () {
    if (this.isAuthenticated && !this.title) {
      this.$router.push('/events')
    }
  },
  metaInfo() {
    return {
      title: this.pageTitle
    }
  }
}
</script>

<template>
  <div class="screen-feedback has-tab-bar">
    <app-bar title="Feedback" :show-back-button="true" />
    <div class="container">
      <p class="margin-top-10">
        We proberen Inviplay altijd te verbeteren, dus we horen graag wat beter
        kan (en wat juist goed werkt). Ook als je een vraag hebt of hulp nodig
        hebt bij een probleem horen wij het graag. Stuur een e-mail naar:
        <a target="_blank" class="link" href="mailto:contact@inviplay.nl">
          contact@inviplay.nl
        </a>
        of stuur ons een DM op
        <a
          target="_blank"
          class="link"
          href="https://www.instagram.com/inviplay/"
        >
          Instagram
        </a>
        .
      </p>
    </div>
    <tab-bar selected="profile" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'

export default {
  name: 'Feedback',
  components: {
    AppBar,
    TabBar
  }
}
</script>

<style scoped></style>

<template>
  <div class="has-tab-bar">
    <app-bar title="" :show-back-button="false" :show-profile-button="false" />
    <spinner-loader v-if="loading" />
    <div class="container">
      <h1>Nodig je vrienden uit en krijg korting!</h1>
      <p>Nodig je vrienden uit en krijg korting op je volgende boeking!</p>
      <input-field
        id="email"
        v-model="email"
        class="mt-4"
        type="email"
        placeholder="Enter email"
      />
      <button class="button button-primary mt-4" @click="sendInvite">
        Verstuur uitnodiging
      </button>
    </div>
    <modal :show="showErrorModal" @close="showErrorModal = false">
      <template v-slot:header>Er ging iets fout...</template>
      <p class="text-sm">Maar wat weet ik niet...</p>
    </modal>
    <tab-bar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import InputField from '@/components/InputField'
import { mapState } from 'vuex'
import api from '@/utils/api'
import Modal from '@/components/Modal'

export default {
  name: 'Referral',
  components: { AppBar, SpinnerLoader, TabBar, InputField, Modal },
  data() {
    return {
      referralUid: this.$route.params.referralUid,
      loading: false,
      showErrorModal: false,
      email: ''
    }
  },
  computed: {
    ...mapState({})
  },
  watch: {},
  created: function () {},
  methods: {
    async sendInvite() {
      this.loading = true
      try {
        await api.post('/referral/sendInvite', {
          emailAddresses: [this.email],
          referralUid: this.referralUid
        })
      } catch (e) {
        this.showErrorModal = true
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>

export default {
  ['book-tennis']: {
    main: {
      title: 'Zin om te tennissen?',
      content:
        'Huur snel en goedkoop een tennisbaan! Download de app, boek een tennisbaan bij jou in de buurt en ga direct de tennisbaan op. Waar wacht je nog op?'
    },
    sub: {
      title: 'Geen lidmaatschap nodig',
      content:
        'Via Inviplay heb je geen lidmaatschap nodig om ergens te tennissen. Via de app kun je makkelijk een tennisbaan huren. Betaal per uur of tennis nog goedkoper en koop een strippenkaart!'
    }
  },
  ['book-squash']: {
    main: {
      title: 'Zin om te squashen?',
      content:
        'Huur snel en goedkoop een squashbaan! Download de app, boek een squashbaan bij jou in de buurt en ga direct de squashbaan op. Waar wacht je nog op?'
    },
    sub: {
      title: 'Geen lidmaatschap nodig',
      content:
        'Via Inviplay heb je geen lidmaatschap nodig om ergens te squashen. Via de app kun je makkelijk een squashbaan huren. Betaal per uur of squash nog goedkoper en koop een strippenkaart!'
    }
  },
  ['book-padel']: {
    main: {
      title: 'Zin om te padellen?',
      content:
        'Huur snel en goedkoop een padelbaan! Download de app, boek een padelbaan bij jou in de buurt en ga direct de padelbaan op. Waar wacht je nog op?'
    },
    sub: {
      title: 'Geen lidmaatschap nodig',
      content:
        'Via Inviplay heb je geen lidmaatschap nodig om ergens te padellen. Via de app kun je makkelijk een padelbaan huren. Betaal per uur of padel nog goedkoper en koop een strippenkaart!'
    }
  },
  ['book-gym']: {
    main: {
      title: 'Huur snel en goedkoop een gymzaal!',
      content:
        'Op zoek naar een goedkope gymzaal bij jou in de buurt? Via Inviplay kun je makkelijk en spontaan een gymzaal huren. Download de app, boek een gymzaal en sport er op los!'
    },
    sub: {
      title: 'Op zoek naar extra spelers?',
      content:
        'Stel via Inviplay een activiteit voor! Andere sporters in jouw buurt krijgen dan een uitnodiging om mee te doen. Extra handig: via Inviplay kun jij iedereen vooraf laten meebetalen, zo hoef jij aan niemand meer geld te vragen!'
    }
  },
  ['book-indoor']: {
    main: {
      title: 'Huur snel en goedkoop een sportzaal!',
      content:
        'Op zoek naar een goedkope sportzaal bij jou in de buurt? Via Inviplay kun je makkelijk en spontaan een sportzaal huren. Download de app, boek een sportzaal en sport er op los!'
    },
    sub: {
      title: 'Op zoek naar extra spelers?',
      content:
        'Stel via Inviplay een activiteit voor! Andere sporters in jouw buurt krijgen dan een uitnodiging om mee te doen. Extra handig: via Inviplay kun jij iedereen vooraf laten meebetalen, zo hoef jij aan niemand meer geld te vragen!'
    }
  },
  ['book-indoor-hall']: {
    main: {
      title: 'Huur snel en goedkoop een sporthal!',
      content:
        'Op zoek naar een goedkope sporthal bij jou in de buurt? Via Inviplay kun je makkelijk en spontaan een sporthal huren. Download de app, boek een sporthal en sport er op los!'
    },
    sub: {
      title: 'Op zoek naar extra spelers?',
      content:
        'Stel via Inviplay een activiteit voor! Andere sporters in jouw buurt krijgen dan een uitnodiging om mee te doen. Extra handig: via Inviplay kun jij iedereen vooraf laten meebetalen, zo hoef jij aan niemand meer geld te vragen!'
    }
  },
  ['activity-soccer']: {
    main: {
      title: 'Voetballen bij jou in de buurt!',
      content:
        'Wil jij met een groepje voetballen? Met vrienden voetballen? Of juist met nieuwe mensen voetballen? Een lekker partijtje zoals vroeger, in het park of in de zaal! Download de app, bekijk de voetbalactiviteiten bij jou in de buurt en doe mee!'
    },
    sub: {
      title: 'Doe zelf een voorstel',
      content:
        'Een leuke pot voetbal organiseren was nog nooit zo makkelijk. Stel via de app voor om te voetballen, dan krijgen andere voetballers in jouw buurt een uitnodiging om mee te doen. Spreek met jouw groepje af in het park of huur via de Inviplay app goedkoop een gymzaal en deel de kosten!'
    }
  },
  ['activity-basketball']: {
    main: {
      title: 'Basketballen bij jou in de buurt!',
      content:
        'Wil jij met een groepje basketballen? Met vrienden basketballen? Of juist met nieuwe mensen basketballen? Pick-up games, op een basketbalpleintje of in de zaal! Download de app, bekijk de basketballactiviteiten bij jou in de buurt en doe mee!'
    },
    sub: {
      title: 'Doe zelf een voorstel',
      content:
        'Zelf pick-up games organiseren was nog nooit zo makkelijk. Stel via de app voor om te basketballen, dan krijgen andere basketballers in jouw buurt een uitnodiging om mee te doen. Spreek met jouw groepje af op een basketbalplein of huur via de Inviplay app goedkoop een gymzaal en deel de kosten!'
    }
  },
  ['activity-futsal']: {
    main: {
      title: 'Zaalvoetballen bij jou in de buurt!',
      content:
        'Wil jij met een groepje zaalvoetballen? Met vrienden zaalvoetballen? Of juist met nieuwe mensen zaalvoetballen? Maak partijen en zaalvoetballen maar! Download de app, bekijk de zaalvoetbalactiviteiten bij jou in de buurt en doe mee!'
    },
    sub: {
      title: 'Doe zelf een voorstel',
      content:
        'Zelf zaalvoetbal organiseren was nog nooit zo makkelijk. Stel via de app voor om te zaalvoetballen, dan krijgen andere zaalvoetballers in jouw buurt een uitnodiging om mee te doen. Huur met jouw groepje via de Inviplay app goedkoop een sporthal, sportzaal of gymzaal en deel de kosten!'
    }
  },
  ['activity-beachvolleyball']: {
    main: {
      title: 'Beachvolleyballen bij jou in de buurt!',
      content:
        'Wil jij met een groepje beachvolleyballen? Met vrienden beachvolleyballen? Of juist met nieuwe mensen beachvolleyballen? Maak teams en beachvolleyballen maar! Download de app, bekijk de beachvolleybalactiviteiten bij jou in de buurt en doe mee!'
    },
    sub: {
      title: 'Doe zelf een voorstel',
      content:
        'Zelf beachvolleybal organiseren was nog nooit zo makkelijk. Stel via de app voor om te beachvolleyballen, dan krijgen andere beachvolleyballers in jouw buurt een uitnodiging om mee te doen. Huur via de Inviplayapp met jouw groepje een beachvolleybalveld en deel de kosten!'
    }
  },
  ['activity-badminton']: {
    main: {
      title: 'Badmintonnen bij jou in de buurt!',
      content:
        'Wil jij met een groepje badmintonnen? Met vrienden badmintonnen? Of juist met nieuwe mensen badmintonnen? Een recreatieve wedstrijd, enkelen of dubbelen! Download de app, bekijk de badmintonactiviteiten bij jou in de buurt en doe mee!'
    },
    sub: {
      title: 'Doe zelf een voorstel',
      content:
        'Zelf badminton organiseren was nog nooit zo makkelijk. Stel via de app voor om te badmintonnen, dan krijgen andere badmintonners in jouw buurt een uitnodiging om mee te doen. Huur via de Inviplay app met jouw groepje goedkoop een gymzaal en deel de kosten!'
    }
  },
  ['activity-swim']: {
    main: {
      title: 'Baanzwemmen bij jou in de buurt!',
      content:
        'Wil jij graag baanzwemmen? Rustig baantjes trekken of juist intensief trainen? Via Inviplay zwem je met vrienden of met nieuwe mensen. Download de app, bekijk de zwemactiviteiten bij jou in de buurt en zwemmen maar!'
    },
    sub: {
      title: 'Doe zelf een voorstel',
      content:
        'Zelf zwemmen organiseren was nog nooit zo makkelijk. Stel via de app voor om te zwemmen, dan krijgen andere zwemmers in jouw buurt een uitnodiging om mee te doen. Spreek met jouw groepje af bij een meertje of zwembad bij jou in de buurt!'
    }
  },
  ['activity-cycling']: {
    main: {
      title: 'Wielrengroepjes bij jou in de buurt!',
      content:
        'Wil jij met een groepje wielrennen? Intensief trainen of juist gezellige social rides? Via Inviplay fiets je met vrienden en met nieuwe mensen. Download de app, vind fietsgroepjes bij jou in de buurt en fietsen maar!'
    },
    sub: {
      title: 'Doe zelf een voorstel',
      content:
        'Zelf wielrenactiviteiten voorstellen was nog nooit zo makkelijk. Stel via de app voor om te fietsen, dan krijgen andere wielrenners in jouw buurt een uitnodiging om mee te doen. Spreek met jouw groepje een vertrekpunt af en fietsen maar!'
    }
  },
  ['activity-running']: {
    main: {
      title: 'Hardlopen bij jou in de buurt!',
      content:
        'Wil jij graag hardlopen? Via Inviplay vind je hardloopgroepjes bij jou in de buurt. Loop met vrienden en met nieuwe mensen. Download de app en begin vandaag nog met hardlopen!'
    },
    sub: {
      title: 'Doe zelf een voorstel',
      content:
        'Zelf hardlopen organiseren was nog nooit zo makkelijk. Stel via de app voor om te hardlopen, dan krijgen andere hardlopers in jouw buurt een uitnodiging om mee te doen. Spreek met jouw groepje een vertrekpunt af en hardlopen maar!'
    }
  },
  default: {
    main: {
      title: 'Bekijk wie er in jouw buurt gaat sporten en doe mee!',
      content:
        'Vind sportactiviteiten of...stel zelf iets voor! Sportlocatie nodig? Huur een tennisbaan, gymzaal of andere locatie en deel de kosten met jouw medespelers.'
    },
    sub: {
      title: 'It pays to play',
      content:
        'Of je nu nieuwe mensen wil leren kennen, fit wil worden of de sport zo leuk vindt: samen sporten maakt het leven leuker.'
    }
  }
}

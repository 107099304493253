<template>
  <modal :show="show" @close="$emit('close')">
    <template v-slot:header>Oeps, er gaat iets fout...</template>
    <p class="text-sm">
      Probeer het nog eens of neem contact met ons op via contact@inviplay.nl,
      dan proberen we het zo snel mogelijk voor je op te lossen.
    </p>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'ErrorModal',
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  created: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>

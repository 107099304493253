<template>
  <div class="my-4 last:mb-0">
    <div class="text-sm flex items-center relative justify-center">
      <input
        :id="value"
        v-model="selected"
        :disabled="disabled"
        type="radio"
        :name="name"
        :value="value"
        class="appearance-none border rounded-full focus:outline-none"
        :class="[
          size === 7 ? 'w-7 h-7' : 'w-6 h-6',
          type === 'maybe'
            ? 'border-yellow-600 checked:bg-yellow-600 check-maybe'
            : type === 'no'
            ? 'border-red-600 checked:bg-red-600 check-no'
            : disabled
            ? 'border-gray-300 checked:bg-gray-300 check-yes'
            : 'border-green-600 checked:bg-green-600 check-yes'
        ]"
        @click="$emit('change', value)"
      />
      <label
        v-if="label"
        class="my-0 ml-4 flex-1"
        :class="disabled && 'text-gray-300'"
        :for="value"
      >
        {{ label }}
      </label>
    </div>
    <div class="ml-10">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    selected: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    label: {
      type: [String],
      default: undefined
    },
    size: {
      type: Number,
      default: 6
    },
    type: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.check-yes:checked {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='-1 -1 26 24' fill='none' stroke='white' stroke-width='2' stroke-linejoin='round' stroke-linecap='round' xmlns='http://www.w3.org/2000/svg'%3e%3cpolyline points='20 6 9 17 4 12' /%3e%3c/svg%3e");
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  /* border-width: 2.5rem; */
}
.check-maybe:checked {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linejoin='round' stroke-linecap='round' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5 12H19' /%3e%3c/svg%3e");
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  /* border-width: 2.5rem; */
}
.check-no:checked {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linejoin='round' stroke-linecap='round' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M18 6L6 18' /%3e%3cpath d='M6 6L18 18' /%3e%3c/svg%3e");
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  /* border-width: 2.5rem; */
}
</style>

<template>
  <div class="has-tab-bar">
    <AppBar :show-back-button="false" title="Tijdelijk onderhoud" />
    <SpinnerLoader v-if="loading" />
    <div class="container">
      <p class="mt-4 mb-5">
        Er wordt op dit moment tijdelijk onderhoud uitgevoerd, we zijn zo snel
        mogelijk weer in de lucht
      </p>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapState } from 'vuex'

export default {
  name: 'Maintenance',
  components: { AppBar, SpinnerLoader },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      loading: (state) => state.group.loading
    })
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
